export const environment = {
  production: true,
  envName: 'stage',
  header: {
    logo: 'logo.png'
  },
  api: {
    endpoint: 'https://api-stage.hello-one.de/api/'
  },
  pusher: {
    public_key: 'd57011e90976d2abd1d7'
  }
};
