import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgIterable,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Moment } from 'moment';
import { ColumnData } from 'src/app/entities/column-data.entity';
import { ColumnVisalizationType } from 'src/app/entities/column-visualization-type.enum';
import { PaginatedResponse } from 'src/app/entities/paginated-response.entity';
import { TableSort } from 'src/app/entities/table-sort.entity';
import {
  ColumnDataSelection,
  TableColumnSettingsComponent,
  TableSettingsDialogData
} from './table-column-settings/table-column-settings.component';
import { MatDialog } from '@angular/material/dialog';
import { FavoriteService } from '../../../services/favorite.service';
import { ExportService } from '../../../services/api/methods/export.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent<T> implements OnInit, OnChanges {
  @Input() public paginatedResponse?: PaginatedResponse<T>;
  @Input() public exportEndpoint?: string;
  @Input() public favoriteKey?: string = '';
  @Input() public columnsSelectable = true;
  @Input() public rowsSelectable = true;
  @Input() public exportModificationFunction?: (input: any) => any;
  @Input() public selectedRows: T[] = [];
  @Input() public loading = false;
  @Input() public sorts: TableSort[] = [];
  @Input() public searchEnabled: boolean = true;
  @Input() public tableTitle: string | null = null;
  @Output() public readonly loadContent = new EventEmitter<{ page: number; search?: string; sorts?: TableSort[] }>();
  @Output() public readonly selectedRowsChange = new EventEmitter<T[]>();
  @Output() public readonly rowClick = new EventEmitter<T>();
  @Output() public readonly updateRow = new EventEmitter<T>();
  public editingCell: { id?: string; value?: string } = {};
  public filteredColumns: ColumnData[] = this.allColumns;
  public ColumnMode = ColumnMode;
  public ColumnVisalizationType = ColumnVisalizationType;
  public search: string = '';
  public pageOffset: number = 1;
  public showBadge?: boolean = false;

  private _allColumns: ColumnData[] = [];

  public get allColumns(): ColumnData[] {
    return this._allColumns;
  }

  @Input() public set allColumns(value: ColumnData[]) {
    this._allColumns = value;
    const activeFavorite = this.favoriteService.selectFavoriteColumnByActiveState(this.favoriteKey!);

    if (activeFavorite?.data) {
      this.filteredColumns = this._allColumns.filter(column => {
        return !!(activeFavorite.data as ColumnDataSelection[]).find(activeFavoriteColumn => activeFavoriteColumn.name == column.name);
      });

      this.filteredColumns.sort(function (a, b) {
        const matchA = (activeFavorite.data as ColumnDataSelection[]).map(column => column.name).indexOf(a.name);
        const matchB = (activeFavorite.data as ColumnDataSelection[]).map(column => column.name).indexOf(b.name);
        return matchA - matchB;
      });
    } else {
      this.filteredColumns = value;
    }
  }

  public onSelect({ selected }): void {
    if (!this.editingCell.id) {
      this.selectedRows.splice(0, this.selectedRows.length);
      this.selectedRows.push(...selected);
      this.selectedRowsChange.emit(selected);
    }
  }

  public onActivate(event): void {
    if (
      event.type === 'click' &&
      this.selectedRows.length === 0 &&
      (event.cellIndex !== 0 || !this.rowsSelectable) &&
      this.allColumns.find(column => column.name === event.column.prop)?.type !== ColumnVisalizationType.link &&
      !this.editingCell.id
    ) {
      this.rowClick.emit(event);
    }
  }

  public onSort(event): void {
    this.sorts = [event.sorts[0]];
    this.loadContent.emit({ page: 1, search: this.search, sorts: this.sorts });
  }

  public onSearch(event): void {
    this.search = event;
    this.loadContent.emit({ page: 1, search: this.search, sorts: this.sorts });
  }

  public ngOnInit(): void {
    //this.filteredColumns = this.allColumns;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.loading) {
      this.cdr.markForCheck();
    }
  }

  public editCell(event: Event, cellName: string, rowIndex: number, row: T): void {
    event.stopPropagation();
    this.editingCell.id = rowIndex + '-' + cellName;
    this.editingCell.value = row[cellName];
  }

  public saveRow(event: Event, row: T, key: string): void {
    event.stopPropagation();
    row[key] = this.editingCell.value;
    this.updateRow.emit(row);
    this.editingCell = {};
  }

  public editCellKeyUp(event: KeyboardEvent): void {
    if (event.which === 27) {
      this.editingCell = {};
    }
  }

  public updateValue(value, columnName, rowIndex, row): void {
    row[columnName] = value;
    this.updateRow.emit(row);
  }

  public momentDatatableComparator(valueA: Moment, valueB: Moment, rowA, rowB, sortDirection): 1 | -1 {
    return valueA.isBefore(valueB) ? -1 : 1;
  }

  public showColumnSettingDialog(): void {
    const data: TableSettingsDialogData = { columns: this._allColumns, filteredColumns: this.filteredColumns, favoriteKey: this.favoriteKey };
    const dialogRef = this.dialog.open(TableColumnSettingsComponent, {
      autoFocus: false,
      width: '20%',
      minWidth: 200,
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.filteredColumns = result;
        this.cdr.markForCheck()
      }
    });
  }

  public exportTable(): void {
    this.exportsService.table(this.exportEndpoint, this.filteredColumns.map(column => column.name), this.selectedRows.map(row => row['id'])).subscribe(blob => {
      this.toastr.success('Report requested via email', 'Success');
    });
  }

  constructor(
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private favoriteService: FavoriteService,
    private exportsService: ExportService,
    private toastr: ToastrService) {
  }
}
